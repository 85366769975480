import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { styleConstants } from "../../style-constants";

interface IExternalLinkProps {
    url: string
    label?: string
    labelPre?: string
    labelPost?: string
    customStyleIcon?: string
    customStyleLink?: string

}


export function ExternalLink(props: IExternalLinkProps) {

    const handleLinkClick = () => {
        window.open(props.url, '_blank');
    };

    const linkStyle = `flex gap-x-2 mx-[2px] cursor-pointer hover:text-secondaryblue hover:underline ${props.customStyleIcon ? props.customStyleIcon : ""}`
    const iconStyle = `${styleConstants["inlineIcon"]} ${styleConstants["transitionFast"]} ${props.customStyleLink ? props.customStyleLink : ""}`

    const inlineExternalIcon = <div className="flex flex-row">
        <span className="inline-block">{props.labelPre && props.labelPre!}</span>
        <div
            className={linkStyle}
            onClick={() => handleLinkClick()}
        >
            {props.label && props.label} <ArrowTopRightOnSquareIcon
                className={iconStyle}
            />
        </div>
        <span className="inline-block">{props.labelPost && props.labelPost!}</span>

    </div>

    return inlineExternalIcon
}