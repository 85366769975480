import './App.css';
import { Navbar } from './components/Navbar';
import { INavlinkProps } from './components/Navlink';
import { RegisterUserPage } from './pages/RegisterUser';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginUserPage } from './pages/LoginUser';
import { Provider } from 'react-redux';
import { store } from './state/store'
import { RedirectComponent } from './components/RedirectComponent';
import { UserPage } from './pages/UserPage';
import { Footer } from './components/Footer';
import { TermsPage } from './pages/TermsPage';

export enum RouterLinks {
  "default" = "/",
  "login" = "login",
  "register" = "register",
  "userpage" = "user-dashboard",
  "terms" = "terms"

}

function App() {

  const navLinks: INavlinkProps[] = [
    { text: "Account", to: RouterLinks.userpage, external: false },
    { text: "API Interface", to: "https://v2.api.carbon-ratings.com/documentation", external: true },
    { text: "API Documentation", to: "https://docs.api.carbon-ratings.com/", external: true },
    { text: "API Pricing", to: "https://carbon-ratings.com/ccri-crypto-sustainability-api-pricing", external: true },
    { text: "CCRI Indices", to: "http://indices.carbon-ratings.com/", external: true },
    { text: "Main Page", to: "http://carbon-ratings.com/", external: true },

  ];

  const footerLinks: INavlinkProps[] = [
    {
      text: "Imprint",
      to: "https://carbon-ratings.com/imprint",
      type: "footer",
      external: true
    },
    {
      text: "Terms & Conditions",
      to: RouterLinks.terms,
      type: "footer"
    }
  ];


  const renderComponent = () => {

    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="min-h-screen h-screen flex flex-col">
            <div className="flex-initial sticky top-0 z-50">
              <Navbar links={navLinks}></Navbar>
            </div>
            <div className="flex flex-grow justify-center">
              <Routes>
                <Route path={RouterLinks.default} element={<LoginUserPage />}></Route>
                <Route path={RouterLinks.userpage} element={<RedirectComponent RenderComponent={UserPage} loginPageUrl={RouterLinks.login} />}></Route>
                <Route path={RouterLinks.login} element={<LoginUserPage />}></Route>
                <Route path={RouterLinks.register} element={<RegisterUserPage />}></Route>
                <Route path={RouterLinks.terms} element={<TermsPage />}></Route>
                <Route path="*" element={<LoginUserPage />}></Route>
              </Routes>
            </div>
              <div className="bottom-0 z-50">
                <Footer links={footerLinks}></Footer>
              </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }

  return renderComponent()
}

export default App;
