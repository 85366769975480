import { useEffect } from "react";
import { useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";

interface IRedirectComponentProps {
    RenderComponent: React.ComponentType;
    loginPageUrl: string;
}


export function RedirectComponent(props: IRedirectComponentProps) {
    
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector(state => state.session.isAuthenticated);
    const RenderComponent = props.RenderComponent;

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/" + props.loginPageUrl);
        }
    }, [isAuthenticated, navigate, props.loginPageUrl]);

    return isAuthenticated ? <RenderComponent/> : <></>;
};

