import termsData from "./termsContent.json"

interface ITermsTableProps {

}


export function TermsTable(props: ITermsTableProps) {

    const renderTermsTableHead = () => {

        const title = "Terms & Conditions";
        const subTitle = "The use of the CCRI-API is subject to the following Terms and Conditions (T&Cs). Please read these T&Cs carefully before using the CCRI-API. By using the CCRI-API, you agree to these T&Cs.";

        return (
            <div className="flex flex-col items-center gap-4 md:flex-row px-4 py-6 sm:px-6">
                <div className="flex-grow w-full">
                    <h3 className="text-center md:text-left text-base font-semibold leading-7 text-gray-900">{title}</h3>
                    <p className="text-center md:text-left mt-1 text-sm leading-6 text-gray-500">{subTitle}</p>
                </div>
            </div>
        )
    }

    const renderTableRow = (rowNumber: number, rowKey: string, rowValues: string[]) => {
        const cardContainerStyle = "flex flex-col md:flex-row px-4 py-6 md:grid md:grid-cols-5 md:gap-4 md:px-6"
        const termElementStyle = "flex self-center mb-2 md:mb-0 font-semibold md:font-normal md:self-start gap-x-4 text-sm font-medium text-gray-900"
        const descriptionElementStyle = "flex flex-col gap-2 mt-1 text-sm leading-6 text-gray-700 md:col-span-4 md:mt-0"

        const rowNumberFormatted = `${rowNumber + 1}.`
        const rowKeyFormatted = `${rowNumberFormatted} ${rowKey}`
        const rowValuesFormatted = rowValues.map((elem, index) => {
            const valueNumberFormatted = `${index + 1}.`
            return <p key={`terms-table-row-${rowNumber}-value-${index}`}>{`${rowNumberFormatted}${valueNumberFormatted} ${elem}`}</p>
        })

        return (<div className={cardContainerStyle} key={`terms-table-row-${rowNumber}`}>
            <dt className={termElementStyle}>{rowKeyFormatted}</dt>
            <dd className={descriptionElementStyle}>{rowValuesFormatted}</dd>
        </div>
        )
    }

    const renderTermsTable = () => {

        const tableRowsInfos = Object.entries(termsData)

        const tableRowsRendered = tableRowsInfos.map((elem, index) => {
            const rowKey = elem[0];
            const rowValues = elem[1];
            return renderTableRow(index, rowKey, rowValues)
        })

        return (<dl className="divide-y divide-gray-100">
            {tableRowsRendered}
        </dl>
        )
    }


    return (
        <div className="text-center sm:text-left flex-row w-full max-w-7xl bg-white px-6 py-8 shadow sm:rounded-md sm:px-8">
            {renderTermsTableHead()}
            <div className="border-t border-gray-100">
                {renderTermsTable()}
            </div>
        </div>
    )
}