import { styleConstants } from "../../style-constants";

interface IButtonProps {
    text: string,
    type: "submit" | "reset"
    customStyle?: string
    disabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export function Button(props: IButtonProps) {

    const style = `flex w-40 justify-center justify-self-center rounded-md bg-secondaryblue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primaryblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:primaryblue ${styleConstants["transition"]} ${props.disabled ? "!bg-slate-400 !hover:bg-slate-400 cursor-not-allowed" : ""} ${props.customStyle && props.customStyle}`

    return (
        <button
            type={props.type}
            className={style}
            disabled={props.disabled || false}
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
}