import { useState, FormEvent, ReactElement } from 'react';
import { IRegisterFormData } from '../../types/user-types';
import { backendRegistrationService } from '../../services/backend-service'
import { Spinner } from '../Spinner';
import { RegistrationForm } from '../RegistrationForm';
import { Alert } from '../Alert';
import { RouterLinks } from '../../App';

function Registration() {

    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);


    const handleRegisterSubmit = (event: FormEvent<HTMLFormElement>) => {
        setLoading(true)
        event.preventDefault(); //prevent pagereload on submit of form

        const formData: IRegisterFormData = {
            name: '',
            company: '',
            street: '',
            street2: '',
            city: '',
            plz: 0,
            state: '',
            country: '',
            email: '',
            password: ''
        }

        const form = event.target as HTMLFormElement; // Type assertion to HTMLFormElement

        Object.keys(formData).forEach(((key) => {
            const typedKey = key as keyof IRegisterFormData
            const element = form.elements.namedItem(typedKey) as HTMLInputElement;
            if (element && 'value' in element) {
                if (typedKey === 'plz') {
                    formData[typedKey] = parseInt(element.value);
                } else {
                    formData[typedKey] = element.value as string;
                }
            }
        }))
        triggerRegistration(formData)
    };



    const triggerRegistration = (registrationData: IRegisterFormData) => {
        backendRegistrationService(registrationData)
            .then((data) => {
                // Handle successful response
                setRegistrationSuccessful(true)
            })
            .catch((error) => {
                // Handle error
                console.error('Error:', error);
                setRegistrationSuccessful(false)

            }).finally(() => {
                setLoading(false)
            });
    }



    const renderComponent = (): ReactElement => {
        let component = <></>
        if (undefined === loading) {
            component = <RegistrationForm
                onFormSubmit={handleRegisterSubmit}
            />
        } else if (loading) {
            component = <Spinner />
        } else if (false === loading && false === registrationSuccessful) {
            component = <Alert
                type={'failure'}
                text={'Your registration failed. Please check your data and try again, or contact us in case of any issues.'}
                buttonInfo={[{ "link": RouterLinks.register, "linkText": "Retry", "isRefresh": true }, { "link": "", "linkText": "Contact us", "isMailto": true }]}
                key={"alertFailure"}
            />
        } else if (false === loading && true === registrationSuccessful) {
            component = <Alert
                type={'success'}
                text={'Your registration was successful. You can now sign-in!'}
                buttonInfo={[{ "link": RouterLinks.login, "linkText": "Sign-in" }]}
                key={"alertSuccess"}
            />
        }
        return component
    }

    return (
        <>
            {renderComponent()}
        </>
    )
}

export default Registration;