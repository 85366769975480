import { AnyAction } from "redux";
import { Dispatch } from "redux";


export enum ActionType {
    AUTHENTICATE = "AUTHENTICATE",
    UN_AUTHENTICATE = "UN_AUTHENTICATE",
    SET_SESSION_KEY = "SET_SESSION_KEY",
    REMOVE_SESSION_KEY = "SET_SESSION_KEY",
    SET_CURRENT_USER_MAIL = "SET_CURRENT_USER_MAIL",
    REMOVE_CURRENT_USER_MAIL = "REMOVE_CURRENT_USER_MAIL"

}

export type Action =
    | SetIsAuthenticatedAction
    | SetIsUnAuthenticatedAction
    | SetAuthenticatedSessionkeyUsermailAction
    | RemoveAuthenticatedSessionkeyUsermailAction
    | AnyAction;


interface SetIsAuthenticatedAction {
    type: ActionType.AUTHENTICATE;

}

interface SetIsUnAuthenticatedAction {
    type: ActionType.UN_AUTHENTICATE;

}

export interface SetAuthenticatedSessionkeyUsermailAction {
    type: ActionType.SET_SESSION_KEY,
    payload: string;
}

interface RemoveAuthenticatedSessionkeyUsermailAction {
    type: ActionType.REMOVE_SESSION_KEY
}


export const setIsAuthenticated = () => {
    return (dispatch: Dispatch<SetIsAuthenticatedAction>) => {
        dispatch({
            type: ActionType.AUTHENTICATE,
        });
    };
};

export const setIsUnAuthenticated = () => {
    return (dispatch: Dispatch<SetIsUnAuthenticatedAction>) => {
        dispatch({
            type: ActionType.UN_AUTHENTICATE,
        });
    };
};

export const setIsAuthenticatedAndSessionKey = (newSessionKey: string, newUserMail: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_SESSION_KEY,
            payload: newSessionKey,
        });
        dispatch({
            type: ActionType.AUTHENTICATE,
        });
        dispatch({
            type: ActionType.SET_CURRENT_USER_MAIL,
            payload: newUserMail,
        });
    };
};


export const setIsUnAuthenticatedAndRemoveSessionKeyAction = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.REMOVE_SESSION_KEY,
        });
        dispatch({
            type: ActionType.UN_AUTHENTICATE,
        });
        dispatch({
            type: ActionType.REMOVE_CURRENT_USER_MAIL,
        });
    };
};
