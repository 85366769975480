interface IHeadingProps {
    text: string,
    isSubheading?: boolean,
    customStyle?: string,
}

export function Heading(props: IHeadingProps) {

    const style = `${props.isSubheading ? "text-base justify-left" : "text-center text-xl justify-center my-5"} flex font-bold pt-4 ${props.customStyle && props.customStyle}`
    return (
        <h5 className={style}>{props.text}</h5>
    );
}