import { TermsTable } from "../../components/TermsTable";

interface ITermsPageProps {

}


export function TermsPage(props: ITermsPageProps) {


    return <>
        <div
            className="relative flex flex-col justify-center gap-y-16 flex-grow items-center justify-start p-4 sm:p-16 w-screen bg-slate-100"
        >
            <TermsTable/>

        </div>
    </>

}