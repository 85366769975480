import Registration from "../../components/Registration/Register";
import ccriLogo from "../../assets/CCRI-logo-only.png"
import { Heading } from "../../components/Heading";



export function RegisterUserPage() {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="sm:mx-auto xs:w-full xs:max-w-sm mt-20">
        {/* <h2 className="mt-10 mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Don't have an account yet? Sign up now!
        </h2> */}
        <img
          className="mx-auto h-10 w-auto"
          src={ccriLogo}
          alt="ccri-logo"
        />
        <Heading
          text="Sign-up for CCRI Sustainability API" 
          />
      </div>
      <Registration />
    </div>
  );
}

