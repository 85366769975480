import React from "react";
import { Disclosure } from "@headlessui/react";
import { ExternalLink } from "../ExternalLink";
import { useNavigate } from "react-router-dom";

export interface INavlinkProps {
  text: string;
  to: string;
  type?: "menu" | "mobile-menu" | "footer";
  active?: boolean;
  external?: boolean
}

const footerLinkClasses: string =
  "hidden md:flex text-white text-sm font-roboto font-regular mr-4 hover:underline underline-offset-8 cursor-pointer";

const mobileMenuClasses =
  "flex font-roboto font-light text-lg text-white hover:underline underline-offset-8 cursor-pointer ";
const activeClasses = " underline underline-offset-8 text-white";
const menuClasses =
  `border-transparent text-white hover:underline underline-offset-8 font-roboto text-xl font-light shrink-0 xl:text-left xl:ml-0 xl:mr-0 ml-4 mr-4 text-center cursor-pointer`;

export const Navlink: React.FC<INavlinkProps> = (props) => {

  const navigate = useNavigate();

  const onLinkClick = () => {
    props.external ? window.open(props.to, '_blank') : navigate(props.to)
  }

  const menuLink = (
    <div
      onClick={() => onLinkClick()}
      className={`${props.active ? activeClasses : " "} ${menuClasses} inline-flex items-center px-1 pt-1 border-b-2`}
    >
      {props.text} {props.external && <ExternalLink url={props.to} customStyleIcon={"ml-2 hover:text-white"} />}
    </div>
  );

  const mobileMenuLink = (
    <Disclosure.Button
      as="div"
      onClick={() => onLinkClick()}
      className={`${props.active ? activeClasses : mobileMenuClasses
        } block pl-3 pr-4 text-lg py-5`}
    >
      {props.text} {props.external && <ExternalLink url={props.to} customStyleIcon={"ml-2 hover:text-white"} />}
    </Disclosure.Button>
  );

  const footerLink = (
    <div
      className={`${footerLinkClasses}`}
      onClick={() => onLinkClick()}
      rel="noreferrer"
    >
      {props.text}
    </div>
  );

  let navlink = null;

  if (props.type === "menu") {
    navlink = menuLink;
  } else if (props.type === "mobile-menu") {
    navlink = mobileMenuLink;
  } else {
    navlink = footerLink;
  }

  return <>{navlink}</>;
};
