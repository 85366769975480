import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react';




interface IAlertProps {
    type: "success" | "failure" | "neutral"
    text: string
    buttonInfo?: { link: string, linkText: string, isMailto?: boolean, isRefresh?: boolean }[]
}

export function Alert(props: IAlertProps) {

    const navigate = useNavigate();

    const baseColor = () => {
        switch (props.type) {
            case "success":
                return "green"
            case "failure":
                return "red"
            case "neutral":
                return "yellow"
        }
    }

    const getTitle = () => {
        switch (props.type) {
            case "success":
                return "Success!"
            case "failure":
                return "Error!"
            case "neutral":
                return "Warning!"
        }
    }

    const getIcon = () => {
        const iconClassNames = `h-5 w-5 text-${baseColor()}-400`
        switch (props.type) {
            case "success":
                return <CheckCircleIcon className={iconClassNames} aria-hidden="true" />
            case "failure":
                return <XCircleIcon className={iconClassNames} aria-hidden="true" />
            case "neutral":
                return <ExclamationTriangleIcon className={iconClassNames} aria-hidden="true" />
        }
    }

    const handleButtonClick = (link: string, isMailto: boolean | undefined, isRefresh: boolean | undefined) => {
        if (isMailto) {
            const emailAddress = 'account@carbon-ratings.com';
            const subject = 'User account issue';
            const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
            window.location.href = mailtoUrl;
            window.location.reload();
        } else if (isRefresh) {
            window.location.reload();
        } else {
            navigate(link);
        }
    }

    const renderButtons = () => {

        if (!props.buttonInfo) {
            return <></>; // Return null if props.buttonInfo is null or undefined
        }
        else {

            return <div className="mt-4 flex justify-end">
                <div className="-mx-2 -my-1.5 flex">
                    {props.buttonInfo!.map((elem, index) => {
                        return <button
                            className={`bg-${baseColor()}-200 rounded-md ${index !== 0 && "ml-4"} mr-4 px-2 py-1.5 w-28 text-sm font-medium text-sm hover:bg-${baseColor()}-100 transition-all duration-700`}
                            type='button'
                            onClick={() => handleButtonClick(elem.link, elem.isMailto, elem.isRefresh)}
                            key={`alert-button-${index}`}
                        >
                            {elem.linkText}
                        </button>
                    })}
                </div>
            </div>
        }


    }

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true); // Initially set the component to be visible

        // Clean up the visibility when the component is unmounted
        return () => {
            setIsVisible(false);
        };
    }, []);

    return (
        <Transition
            show={isVisible}
            enter="transition-all duration-300 ease-in-out"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-300 ease-in-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-50"
        >

            <div className='flex justify-center'>
                <div className={`flex flex-col rounded-md bg-${baseColor()}-50 p-4 m-8 w-[600px]`}>
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {getIcon()}
                        </div>
                        <div className="ml-3">
                            <h3 className={`text-sm font-medium text-${baseColor()}-800`}>{getTitle()}</h3>
                            <div className={`mt-2 text-sm text-${baseColor()}-700`}>
                                <p>{props.text}</p>
                            </div>
                        </div>
                    </div>
                    {renderButtons()}
                </div>
            </div>
        </Transition>
    )
}