import { FormEvent, ReactElement, useEffect, useState } from "react";
import { Spinner } from "../Spinner";
import { Alert } from "../Alert"
import { LoginForm } from "../LoginForm";
import { ILoginFormData } from "../LoginForm/loginFormConstants";
import { backendLoginService } from "../../services/backend-service";
import { setIsAuthenticatedAndSessionKey, setIsUnAuthenticatedAndRemoveSessionKeyAction } from "../../state/actions/sessionActions";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";
import { RouterLinks } from "../../App";

interface ILoginProps {

}

export function Login(props: ILoginProps) {

    const dispatch = useAppDispatch();  
    const navigate = useNavigate();


    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const [loginSuccessful, setLoginSuccessful] = useState<boolean | undefined>(undefined);

    // const isAuthenticated = useSelector((state) => state.)
    const sessionID = useAppSelector((state) => state.session.sessionId)
    const isAuthenticated = useAppSelector((state) => state.session.isAuthenticated)



    const handleLoginSubmit = (event: FormEvent<HTMLFormElement>) => {
        setLoading(true)
        event.preventDefault(); //prevent pagereload on submit of form

        const form = event.target as HTMLFormElement; // Type assertion to HTMLFormElement
        const username = (form.elements.namedItem("email") as HTMLInputElement).value;
        const password = (form.elements.namedItem("password") as HTMLInputElement).value;
        const loginData  = {username, password}

        triggerLogin(loginData)
    };


    useEffect(() => {
        if(undefined !== sessionID && false !== isAuthenticated){
            navigate("/" + RouterLinks.userpage);
        }
      }, [loginSuccessful]);

        
    const triggerLogin = (loginData: ILoginFormData) => {
        backendLoginService(loginData)
            .then((sessionKey: string) => {
                // Handle successful response
                dispatch(setIsAuthenticatedAndSessionKey(sessionKey, loginData.username));
                setLoginSuccessful(true)
            })
            .catch((error) => {
                // Handle error
                console.error('Error:', error);
                dispatch(setIsUnAuthenticatedAndRemoveSessionKeyAction());
                setLoginSuccessful(false)

            }).finally(() => {
                setLoading(false)
            });
    }



    const renderComponent = (): ReactElement => {
        const loginForm = < LoginForm
            disabled={loading || false}
            onFormSubmit={handleLoginSubmit}
        />
        const loadingSpinner = <div
            className="absolute right-0 left-0 flex items-center justify-center -mt-2"
        >
            <Spinner />
        </div>
        const loginFailedAlert = <div
            className="fixed top-[50px] right-0 left-0"
        >
            <Alert
                type={'failure'}
                text={'Login failed. Please check username or password and try again, or contact us in case of any issues.'}
                buttonInfo={[{ "link": "", "linkText": "Retry", "isRefresh": true }, { "link": "", "linkText": "Contact us", "isMailto": true }]}
                key={"alertFailure"}
            />
        </div>

        return (
            <>
                {loginForm}
                {(loading) && loadingSpinner}
                {(false === loading && false === loginSuccessful) && loginFailedAlert}

            </>
        )
    }


    return (
        <>
            {renderComponent()}
        </>
    )
}