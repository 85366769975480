import { Action, ActionType, SetAuthenticatedSessionkeyUsermailAction } from "../actions/sessionActions";

export type TSessionId = string | undefined

interface ISessionReducerState {
    sessionId: TSessionId,
    isAuthenticated: boolean,
    currentUserMail: string | undefined,
}
const initialState: ISessionReducerState = {
    sessionId: undefined,
    isAuthenticated: false,
    currentUserMail: undefined
};


export const sessionReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.AUTHENTICATE: {
            return {
                ...state,
                isAuthenticated: true,
            };
        }
        case ActionType.UN_AUTHENTICATE: {
            return {
                ...state,
                isAuthenticated: false,
            };
        }
        case ActionType.SET_SESSION_KEY: {
            return {
                ...state,
                sessionId: (action as SetAuthenticatedSessionkeyUsermailAction).payload
            };
        }
        case ActionType.REMOVE_SESSION_KEY: {
            return {
                ...state,
                sessionId: undefined,
            };
        }
        case ActionType.SET_CURRENT_USER_MAIL: {
            return {
                ...state,
                currentUserMail: (action as SetAuthenticatedSessionkeyUsermailAction).payload,
            };
        }
        case ActionType.REMOVE_CURRENT_USER_MAIL: {
            return {
                ...state,
                currentUserMail: undefined,
            };
        }
        default:
            return state;
    }
};