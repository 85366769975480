import { Link } from "react-router-dom";
import { styleConstants } from "../../style-constants";
import { Button } from "../Button";
import { RouterLinks } from "../../App";

interface ILoginFormProps {
    disabled: boolean
    onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export function LoginForm(props: ILoginFormProps) {


    return (
        <div className="flex flex-col justify-center w-full max-w-xl px-6 pt-6 lg:px-8 bg-white">


            <form className="space-y-6" onSubmit={props.onFormSubmit}>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            disabled={props.disabled}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>


                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                            disabled={props.disabled}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className='pt-2 flex justify-center'>
                    <Button
                        disabled={props.disabled}
                        text='Submit'
                        type='submit'
                    />
                </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
                Don't have an account?{' '}
                <Link
                    to={"/" + RouterLinks.register}
                    className={`font-semibold text-primaryblue hover:text-secondaryblue ${styleConstants["transitionFast"]}`}>
                    Click here to get free API key now!
                </Link>
            </p>
        </div>
    )
}