import { IUserStatistics } from "../../types/user-types"
import { Heading } from "../Heading"

interface IUserStatisticsProps {
  userStatistics: IUserStatistics
}

const stats = [
  { name: 'Number of requests', value: '-', change: '-', changeType: '-' },
  { name: 'Failed requests', value: '-', change: '-', changeType: '-' },
  { name: 'Successful requests', value: '-', change: '-', changeType: '-' },
  { name: 'Coins requested', value: '-', change: '-', changeType: '-' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// export function UserStatistics(props: IUserStatisticsProps) {
export const UserStatistics: React.FC<IUserStatisticsProps> = (props) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <Heading text="API usage statistic coming soon!" customStyle="text-primaryblue text-3xl font-semibold !p-0 !m-0" />
      </div>
      <dl className=" opacity-20 mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 sm:px-6 xl:px-8"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
            <dd
              className={classNames(
                stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                'text-xs font-medium'
              )}
            >
              {stat.change}
            </dd>
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}