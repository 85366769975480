import { ReactElement, useEffect, useState } from "react";
import { IUserData, IUserStatistics } from "../../types/user-types";
import { useAppSelector } from "../../state/hooks";
import { TSessionId } from "../../state/reducers/sessionReducer";
import { backendFetchNewApiKey, backendFetchUpdateApiKey, backendFetchUserData } from "../../services/backend-service";
import { Spinner } from "../../components/Spinner";
import { Alert } from "../../components/Alert";
import { UserInformation } from "../../components/UserInformation";
import { UserStatistics } from "../../components/UserStatistics";
import { IKeyData } from "../../types/key-types";

interface IUserPageProps {

}


export function UserPage(props: IUserPageProps) {

    const [dataLoading, setDataLoading] = useState<boolean>(true)
    const [loadUserDataSuccessful, setLoadUserDataSuccessful] = useState<boolean | undefined>(undefined);
    const [loadUserStatsSuccessful, setLoadUserStatsSuccessful] = useState<boolean | undefined>(undefined);
    const [userData, setUserData] = useState<IUserData | undefined>(undefined)
    const [userStatistics, setUserStatistics] = useState<IUserStatistics | undefined>(undefined)
    const [newApiKeyLoading, setNewApiKeyLoading] = useState<boolean>(false)
    const [newApiKeyLoadingSuccessful, setNewApiKeyLoadingSuccessful] = useState<boolean | undefined>(undefined);



    const sessionID = useAppSelector<TSessionId>((state) => state.session.sessionId)
    const isAuthenticated = useAppSelector((state) => state.session.isAuthenticated)


    const triggerLoadUserData = () => {
        backendFetchUserData(sessionID!)
            .then((userData: IUserData) => {
                // Handle successful response
                setUserData(userData)
                setLoadUserDataSuccessful(true)
            })
            .catch((error) => {
                // Handle error
                setLoadUserDataSuccessful(false)

            })
    }

    const triggerLoadUserStatistics = () => {
        //TODO change 
        setUserStatistics({
            "stat1": 12,
            "old1": 12,
            "stat2": 12,
            "old2": 12,
            "stat3": 12,
            "old3": 12,
        })
        setLoadUserStatsSuccessful(true)
    }

    const loadInitialData = () => {
        triggerLoadUserData()
        triggerLoadUserStatistics()
        setDataLoading(false)
    }

    // const triggerLoadNewApiKey = () => {
    //     if (userData) {
    //         setNewApiKeyLoading(true)
    //         backendFetchNewApiKey(sessionID!)
    //             .then((newUserApiKeys: IKeyData) => {
    //                 // Handle successful response
    //                 const updatedUserData: IUserData = {
    //                     ...userData,
    //                     keys: [newUserApiKeys]
    //                 }
    //                 setUserData(updatedUserData)
    //                 setNewApiKeyLoadingSuccessful(true)

    //             })
    //             .catch((error) => {
    //                 // Handle error
    //                 console.log("ERROR", error)
    //                 setNewApiKeyLoadingSuccessful(false)

    //             })
    //             .finally(() => {
    //                 setNewApiKeyLoading(false)
    //             })
    //     }
    // }

    const triggerLoadApiKey = () => {
        if (userData) {
            setNewApiKeyLoading(true)
            const backendFetchApiKeyFunction  = userData.keys.length > 0 ? backendFetchUpdateApiKey : backendFetchNewApiKey
            backendFetchApiKeyFunction(sessionID!)
                .then((updatedUserApiKeys: IKeyData) => {
                    // Handle successful response
                    const updatedUserData: IUserData = {
                        ...userData,
                        keys: [updatedUserApiKeys]
                    }
                    setUserData(updatedUserData)
                    setNewApiKeyLoadingSuccessful(true)

                })
                .catch((error) => {
                    // Handle error
                    setNewApiKeyLoadingSuccessful(false)

                })
                .finally(() => {
                    setNewApiKeyLoading(false)
                })
        }
    }

    useEffect(() => {
        if (isAuthenticated && sessionID) {
            loadInitialData()
        }
    }, []);


    const renderComponent = (): ReactElement => {

        const userPageComponentStyle = "flex-row w-full max-w-7xl bg-white px-6 py-8 shadow sm:rounded-md sm:px-8"
        const userPageContent = <>
            {(false === newApiKeyLoadingSuccessful && false === newApiKeyLoading) &&
                <div className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                    <Alert
                        type={'failure'}
                        text={'Generation of new API-key failed. Please try again later and contact us of the error persists.'}
                        buttonInfo={[{ "link": "", "linkText": "Close", "isRefresh": true }, { "link": "", "linkText": "Contact us", "isMailto": true }]}
                        key={"alertFailure"} />
                </div>
            }
            <div
                className={userPageComponentStyle}
            >
                <UserInformation userData={userData!} newKeyLoading={newApiKeyLoading} onKeyRequest={triggerLoadApiKey} />
            </div>
            <div
                className={userPageComponentStyle}
            >
                <UserStatistics userStatistics={userStatistics!} />
            </div>
        </>

        const loadingSpinner = <div
            className="absolute right-0 left-0 flex items-center justify-center -mt-8"
        >
            <Spinner />
        </div>

        const dataLoadingFailedAlert = <div
            className="fixed top-[50px] right-0 left-0"
        >
            <Alert
                type={'failure'}
                text={'Fetching user data failed. Please try again. Contact us of the error persists.'}
                buttonInfo={[{ "link": "", "linkText": "Try again", "isRefresh": true }, { "link": "", "linkText": "Contact us", "isMailto": true }]}
                key={"alertFailure"}
            />
        </div>

        if (dataLoading) {
            return loadingSpinner
        } else if (false === dataLoading && (!loadUserDataSuccessful || !loadUserStatsSuccessful)) {
            return dataLoadingFailedAlert
        } else if (false === dataLoading && loadUserDataSuccessful && loadUserStatsSuccessful && userData && userStatistics) {
            return userPageContent
        } else {
            return <></>
        }
    }

    return (
        <>
            <div
                className="relative flex flex-col justify-center gap-y-16 flex-grow items-center justify-start p-4 sm:p-16 w-screen bg-slate-100"
            >
                {renderComponent()}

            </div>
        </>
    );
}