import { setIsUnAuthenticatedAndRemoveSessionKeyAction } from "../../state/actions/sessionActions"
import { useAppDispatch, useAppSelector } from "../../state/hooks"
import { IUserData } from "../../types/user-types"
import { Button } from "../Button"
import { ReactNode, useEffect, useState } from "react"
import { Spinner } from "../Spinner"
import { IKeyData } from "../../types/key-types"
import { ISubscriptionData } from "../../types/subscription-types"


interface IUserInformationProps {
  userData: IUserData,
  newKeyLoading: boolean,
  onKeyRequest: () => void,
}

export const UserInformation: React.FC<IUserInformationProps> = (props) => {

  const dispatch = useAppDispatch();

  type TTimeout = ReturnType<typeof setTimeout>;
  const [isUpdateKeyButtonDisabled, setUpdateKeyButtonDisabled] = useState(false);
  const [isUpdateKeyButtonDisabledTimeoutId, setIsUpdateKeyButtonDisabledTimeoutId] = useState<TTimeout | undefined>(undefined);




  const clearKeyButtonTimeout = () => {
    if (isUpdateKeyButtonDisabledTimeoutId) {
      clearTimeout(isUpdateKeyButtonDisabledTimeoutId);
      setUpdateKeyButtonDisabled(false);
    }
  };

  useEffect(() => {
    return () => {
      clearKeyButtonTimeout();
    };;
  }, [isUpdateKeyButtonDisabledTimeoutId]);

  const triggerLogout = () => {
    dispatch(setIsUnAuthenticatedAndRemoveSessionKeyAction());
  }

  const stringFirstCharUpperCase = (inputString: string) => {
    if (inputString.length === 0) {
      return inputString;
    } else {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }
  }

  const renderAddressInfo = () => {
    return `${props.userData.street} ${props.userData.street2 ? `(${props.userData.street2})` : ""}, ${props.userData.plz} ${props.userData.city}, ${props.userData.state}, ${props.userData.country}`
  }

  const convertListContentToText = (keyOrSubscription: "keys" | "subscriptions", emptyListText: string) => {
    const input = props.userData[keyOrSubscription]

    if (input.length > 0) {
      let inputList: string[] = []
      if (keyOrSubscription == "keys") {
        inputList = [(input.slice(-1)[0] as IKeyData).key];
      }
      if (keyOrSubscription == "subscriptions") {
        inputList = input.map((subscriptionData) => (subscriptionData as ISubscriptionData).subscription);
      }
      const listText = inputList.slice(0, -1).join(', ') + (inputList.length > 1 ? ' and ' : '') + inputList[inputList.length - 1];
      return listText
    } else {
      return emptyListText
    }
  }

  const renderSubscriptionsInfo = () => {
    const subscriptionsText = convertListContentToText("subscriptions", "Free (non-commercial use only)")
    const onNewSubscriptionRequest = () => {
      const emailAddress = 'account@carbon-ratings.com';
      const subject = `Subscription request for ${props.userData.email}`;
      const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoUrl;
    }
    const apiSubscriptionsContentRendered = renderButtonCard(subscriptionsText, "Contact us", onNewSubscriptionRequest, false)
    return apiSubscriptionsContentRendered
  }

  const renderApiKeysInfo = () => {
    const apiKeysText = convertListContentToText("keys", "You don't have an API yet!")
    const apiKeyPresent = props.userData.keys.length > 0
    const onRequestKeyButtonClicked = () => {
      if (isUpdateKeyButtonDisabledTimeoutId) {
        clearTimeout(isUpdateKeyButtonDisabledTimeoutId); // Clear the previous timeout if it exists
        setIsUpdateKeyButtonDisabledTimeoutId(undefined)
      }
      setUpdateKeyButtonDisabled(true)
      const keyButtonTimeoutId = setTimeout(() => {
        setUpdateKeyButtonDisabled(false);
        setIsUpdateKeyButtonDisabledTimeoutId(undefined)
      }, 30000); // 30 seconds in milliseconds
      setIsUpdateKeyButtonDisabledTimeoutId(keyButtonTimeoutId)
      props.onKeyRequest()
    }
    const apiKeysContentRendered = renderButtonCard(apiKeysText, apiKeyPresent ? "Update key" : "Add free key", onRequestKeyButtonClicked, isUpdateKeyButtonDisabled)
    return (props.newKeyLoading) ? <Spinner customStyle="!my-0" /> : apiKeysContentRendered
  }

  const renderButtonCard = (text: string, buttonText: string, onButtonClick: () => void, buttonDisabled: boolean) => {
    return (
      <div
        className="flex flex-col sm:flex-row items-center gap-4">
        {text}
        <Button
          customStyle={`!font-light !text-xs !px-[6px] w-[100px]`}
          text={buttonText}
          type={"submit"}
          onClick={onButtonClick}
          disabled={buttonDisabled}
          key={`button-${buttonText}`}
        />
      </div >)
  }

  const renderUserInfoTableHead = () => {

    const onLogoutButtonClick = () => {
      triggerLogout()
    }

    return (
      <div className="flex flex-col items-center gap-4 md:flex-row px-4 py-6 sm:px-6">
        <div className="flex-grow w-full">
          <h3 className="text-center md:text-left text-base font-semibold leading-7 text-gray-900">{stringFirstCharUpperCase(props.userData.name)}</h3>
          <p className="text-center md:text-left mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and CCRI Sustainability API key.</p>
        </div>
        <div
          className="flex-grow flex-shrink-0 md:w-auto"
        >
          <Button
            text="Logout"
            type="submit"
            onClick={onLogoutButtonClick}
          />
        </div>
      </div>
    )
  }

  const renderTableRow = (rowLabel: string, rowKey: string, rowContent: ReactNode) => {
    const cardContainerStyle = "flex flex-col sm:flex-row items-center px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:h-[80px] sm:max-h-[80px]"
    const termElementStyle = "flex gap-x-4 text-sm font-medium text-gray-900"
    const descriptionElementStyle = "flex flex-col sm:flex-row gap-2 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
    return (<div className={cardContainerStyle} key={rowLabel}>
      <dt className={termElementStyle}>{rowKey}</dt>
      <dd className={descriptionElementStyle}>{rowContent}</dd>
    </div>
    )
  }

  const renderUserInfoTable = () => {

    const tableRowsInfos: [string, ReactNode][] = [
      ["E-Mail address", props.userData.email],
      ["Name", stringFirstCharUpperCase(props.userData.name)],
      ["Company", stringFirstCharUpperCase(props.userData.company)],
      ["Address", renderAddressInfo()],
      ["Subscriptions", renderSubscriptionsInfo()],
      ["API Keys", renderApiKeysInfo()]
    ]

    const tableRowsRendered = tableRowsInfos.map((row, index) => {
      return renderTableRow(`row-${index}`, row[0], row[1])
    })

    return (<dl className="divide-y divide-gray-100">
      {tableRowsRendered}
    </dl>
    )
  }

  return (
    <div className="text-center sm:text-left">
      {renderUserInfoTableHead()}
      <div className="border-t border-gray-100">
        {renderUserInfoTable()}
      </div>
    </div>
  )
}