import { XCircleIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { RouterLinks } from "../../App";
import { styleConstants } from "../../style-constants";
import { useState } from "react";

interface IRegistrationFormProps {
    onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;

}

export function RegistrationForm(props: IRegistrationFormProps) {

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value
        setPassword(newPassword);
        handlePasswordValidation(newPassword, confirmPassword)
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newConfirmPassword = event.target.value
        setConfirmPassword(newConfirmPassword);
        handlePasswordValidation(password, newConfirmPassword)
    };

    const handlePasswordValidation = (password: string, confirmPassword: string) => {
        setPasswordsMatch(password === confirmPassword);
    };

    return (
        <div className="flex flex-col justify-center w-full xl:max-w-4xl max-w max-w-xl px-6 pt-6 pb-12 lg:px-8 bg-white">


            <div className="mt-4"
            // className="mt-4 sm:mx-auto md:w-11/12 md:max-w-lg"
            >
                <form className="space-y-6" onSubmit={props.onFormSubmit}>
                    <div className="flex flex-col xl:flex-row gap-y-8 xl:gap-y-0 xl:gap-x-8 ">
                        <div className="flex flex-col justify-between w-full xl:w-1/2 gap-y-8">
                            <div className="flex flex-col gap-y-8">
                                <Heading
                                    text='Personal information'
                                    isSubheading
                                    customStyle="mb-4"
                                />
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Name *
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            autoComplete="name"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        // onChange={handleEmailChange}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                                        Company *
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="company"
                                            name="company"
                                            type="text"
                                            autoComplete="organization"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        // onChange={handleEmailChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-y-8">
                                <Heading
                                    text='Account settings'
                                    isSubheading
                                    customStyle="mb-4"
                                />

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email address *
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        // onChange={handleEmailChange}
                                        />
                                    </div>
                                </div>


                                <div>
                                    <div className="flex items-center justify-between">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            Choose a password *
                                        </label>
                                    </div>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            onChange={handlePasswordChange}
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-between">
                                        <label htmlFor="confirmPassword" className="flex justify-between items-center w-full text-sm font-medium leading-6 text-gray-900">
                                            Confirm password *
                                            {false === passwordsMatch &&
                                                <div className='flex flex-row items-center text-red-400'>
                                                    No match!
                                                    <XCircleIcon
                                                        className='ml-2 h-5 w-5'
                                                     />
                                                </div>}
                                        </label>
                                    </div>
                                    <div className="mt-2">
                                        <input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            required
                                            onChange={handleConfirmPasswordChange}
                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-8 w-full xl:w-1/2">
                            <Heading
                                text='Address information'
                                isSubheading
                                customStyle="mb-4"
                            />
                            <div>
                                <label htmlFor="street" className="block text-sm font-medium leading-6 text-gray-900">
                                    Street, number *
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="street"
                                        name="street"
                                        type="text"
                                        autoComplete="address-line1"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>


                            <div>
                                <label htmlFor="street2" className="block text-sm font-medium leading-6 text-gray-900">
                                    Address supplement
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="street2"
                                        name="street2"
                                        type="text"
                                        autoComplete="address-line2"
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                    City *
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="city"
                                        name="city"
                                        type="text"
                                        autoComplete="address-line3"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="plz" className="block text-sm font-medium leading-6 text-gray-900">
                                    Postal code *
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="plz"
                                        name="plz"
                                        type="number"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                                    State *
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="state"
                                        name="state"
                                        type="text"
                                        autoComplete="address-line4"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Country *
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="country"
                                        name="country"
                                        type="text"
                                        autoComplete="address-line1"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    // onChange={handleEmailChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="flex items-center justify-center">
                            <input
                                id="accept-terms"
                                name="accept-terms"
                                type="checkbox"
                                required
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                                <p className="text-center text-sm text-gray-500">
                                    I read and accept the
                                    {' '}
                                    <Link
                                        to={"/" + RouterLinks.terms}
                                        className={`font-semibold text-primaryblue hover:text-secondaryblue ${styleConstants["transitionFast"]}`}>
                                        terms and conditions.
                                    </Link>
                                    {' '}
                                    *
                                </p>
                            </label>
                        </div>
                    </div>

                    <div className='pt-8 flex flex-col items-center justify-center'>
                        <Button
                            text='Submit'
                            type='submit'
                            disabled={false === passwordsMatch}
                        />
                        <p className="mt-8 text-center text-sm text-gray-500">
                            <Link
                                to={"/" + RouterLinks.login}
                                className={`font-semibold text-primaryblue hover:text-secondaryblue ${styleConstants["transitionFast"]}`}>
                                Go back to login
                            </Link>
                        </p>
                    </div>
                </form>
            </div >
        </div >
    )
}