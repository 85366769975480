import { Heading } from "../../components/Heading";
import { Login } from "../../components/Login";
import ccriLogo from "../../assets/CCRI-logo-only.png";
import backgroundImage from "../../assets/header-full.png";



export function LoginUserPage() {

  const backgroundUrl = `url(${backgroundImage})`;

  return (
    <div
      className="relative flex flex-grow items-center justify-center w-screen bg-[url] bg-cover bg-center"
      style={{ backgroundImage: backgroundUrl }}
    >
      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <div className="xs:w-full xs:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={ccriLogo}
            alt="ccri-logo"
          />
          <Heading
            text="Sign-in to your CCRI Sustainability API account"
          />
          <Login/>
        </div>

      </div>
    </div>
  );
}

